<template>
  <div class="mod">跳转中</div>
  <ul id="last_right_b" class="last_right">
    <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
  </ul>
</template>

<script>
import Utils from '@/utils/utils'

export default {
  name: 'to_xcx',
  data () {
    return {
      is_ios: Utils.is_ios()
    }
  },
  mounted () {
    Utils.go_to_xcx('to_xcx', this.$route.query.sid)
  },
  methods: {
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style scoped>

</style>
